import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createHashRouter as createBrowserRouter } from 'react-router-dom';
import { Route, RouterProvider, createRoutesFromElements  } from 'react-router';
import Home from './components/Home';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />} >
       <Route index={true} path='/' element={<Home />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
);


